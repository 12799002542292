/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import { AppContext } from "~context/AppContext";
import Layout from "~components/Layout";
import SEO from "~components/SEO";

const ContactPage = ({ data, location }) => {
  const { setBackground } = useContext(AppContext);
  const { frontmatter } = data.markdownRemark;

  //

  useEffect(() => {
    setBackground(`white`);
  }, []);

  //

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout className="contact-page w-full relative pt-48 xs:pt-32">
        <section className="grid xs:pt-6">
          <article className="grid-end-12">
            <h1 className="animation-appear-right-slow animation-delay-2 mb-20 xs:mb-10 f1">
              <a href={`mailto:${frontmatter.email}`}>{frontmatter.email}</a>
            </h1>

            <h2
              className="animation-appear-right-slow animation-delay-3 mb-20 xs:mb-10 f1"
              dangerouslySetInnerHTML={{ __html: frontmatter.address }}
            ></h2>

            <h3 className="animation-appear-right-slow animation-delay-4 mb-24 xs:mb-0 f1">
              <a href={`tel:${frontmatter.phoneClickable}`}>
                {frontmatter.phoneDisplayed}
              </a>
            </h3>
          </article>
        </section>
      </Layout>
    </>
  );
};

export default ContactPage;

export const query = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        email
        address
        phoneDisplayed
        phoneClickable
        seoDescription
        seoKeywords
      }
    }
  }
`;
